<template>
    <section class="alighting">
        <div v-if="uplineUser" class="alighting-card">
            <div class="alighting-card__image">
                <UiAvatar :user="uplineUser" />
            </div>

            <h2 class="alighting-card__title section-title">
                {{ uplineUser.name }}
            </h2>
            <h3 class="alighting-card__subtitle">{{ $t("register.allLightning.subtitle") }}</h3>
            <div class="alighting-card__inner">
                <div class="alighting-card-info" v-if="calcStatsClassic">
                    <div class="alighting-card-info__item">
                        <p>{{ $t("register.partnerCount") }}</p>
                        <span> {{ calcStatsClassic.partners }} </span>
                    </div>

                    <!-- <div class="alighting-card-info__item">
                        <p>{{ $t("register.item.earned") }} DAI</p>
                        <span> {{ calcStatsClassic.busd }} </span>
                    </div> -->
                </div>
                <button class="alighting-card__now glob-btn btn-reset" @click="handleSignupClick">
                    {{ $t("register.registerNow") }}
                </button>

                <button
                    class="alighting-card__wrong-button btn-reset"
                    type="button"
                    @click="() => (requestChange = !requestChange)"
                >
                    {{ $t("register.upLineError") }}
                </button>

                <UplineSearch
                    class="registry-card-wrong"
                    :visible="requestChange"
                    :uplineData="uplineData"
                    @onError="handleSearchError"
                    @onData="handleSearchData"
                />
                <div class="alighting-card__wrong-instruction">
                    <span>{{ $t("register.instruction") }}</span>
                    <div class="keyFrameSvg">
                        <svg width="23" height="14" viewBox="0 0 23 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 1L11.5 7L18 1" stroke="#38C948" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M1 3L11.5 13L22 3" stroke="#38C948" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                </div>
            </div>
        </div>

        <div v-else>
            <UiLoader theme="block" :loading="true" />
        </div>

        <div class="page-loader" :class="[showPageLoader && 'is-active']">
            <UiLoader :loading="true" />
            <div class="page-loader__message">{{ $t("pleaseWait") }}, <br />{{ $t("operationInProgress") }}</div>
        </div>
        <HowRegister />
    </section>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex"
import { computeStats, nullEmptyHash } from "@/helpers/matrix"
import UplineSearch from "@/components/Register/UplineSearch.vue"
import HowRegister from "@/components/Register/HowRegister.vue"
import { LSTORAGE } from "@/config/constants"
import { setLocalStorageElement } from "@/helpers/localstorage"
import { getConnectTypeFromSelectWallet } from "@/helpers/params"

export default {
    components: {
        HowRegister,
        UplineSearch,
    },

    props: ["referral"],
    data() {
        return {
            loading: false,
            showPageLoader: false,
            uplineUser: null,
            parentCount: 0,
            requestChange: false,
            uplineData: null,
            uplineError: false,
        }
    },

    async mounted() {
        await this.guessUser(this.referral)
        .then((users) => {
                this.uplineUser = users
                // const lsReferral = getLocalStorageElement(LSTORAGE.referral)

                // const shouldCheck = this.$route.query.ignoreUplineRef === undefined
                setLocalStorageElement(LSTORAGE.referral, users)
                // if (users.account !== lsReferral) {
                // this.$swal(`${this.$t("register.useReferral")}`)
                // this.$router.push({
                //     name: "referral",
                //     params: { referral: lsReferral },
                // })
                // }
            })
            .catch((err) => {
                this.$swal(this.$t("errors.accountNotFound"))
                this.$router.push({ name: "signup" })
            })

        if (this.uplineUser && this.uplineUser.account) {
            await this.getParentsCount({ account: this.uplineUser.account }).then(({ success, count }) => {
                if (success) {
                    this.parentCount = count
                }
            })
        }
    },
    computed: {
        calcStatsClassic() {
            if (this.uplineUser && this.uplineUser.classic) {
                // return computeStats(this.uplineUser.classic)
                return {
                    partners: this.parentCount,
                    busd: 0,
                }
            }
            return null
        },
        ...mapGetters("crypto", [
            "getMainContract",
            "getAccount",
            "isConnected",
            "isConnecting",
            "getConnectionWallet",
        ]),
    },
    methods: {
        handleSignupClick() {
            if (this.loading) return

            // this.setConnectionModal({ visible: true, type: "select" })
            this.$router.push({name: "signup"})
        },

        async register() {
            if (this.loading) return

            this.loading = true

            let parentAcc = this.uplineUser && this.uplineUser.account
            if (!parentAcc) {
                this.$swal(this.$t("errors.accountNotFound"))
                this.$router.push({ name: "signup" })
                this.loading = false
                return
            }

            const [connectErr, account] = await this.checkConnect()
            if (connectErr) {
                this.$swal(connectErr.message)
                this.loading = false
                return
            }

            const validationStatus = nullEmptyHash(await this.getMainContract.methods.parent(account).call())

            if (!validationStatus) {
                // если не найден, регистриурем в контракт и бек
                const [regErr, regRes] = await this.registerNewAccount({
                    account,
                    parentAcc: this.uplineUser.account,
                    onBlockchainPending: () => {
                        this.showPageLoader = true
                    },
                })

                if (regRes) {
                    this.$swal(this.$t("register.success"))
                    this.$router.push({ name: "dashboard" })
                } else {
                    this.$swal(regErr.message)
                }
            } else {
                this.$swal(this.$t("register.alreadyRegistered"))
                this.$router.push({ name: "dashboard" })
            }

            this.showPageLoader = false
            this.loading = false
        },
        handleSearchError(error) {
            this.uplineError = error
            if (error) {
                this.$swal(this.$t("errors.userNotFound"))
            }
        },
        handleSearchData(upline) {
            this.uplineData = upline
            if (upline) {
                this.$router.push({ name: "referral", params: { referral: upline.name } })
            }
        },

        ...mapActions("crypto", ["checkConnect", "registerNewAccount"]),
        ...mapActions("user", ["guessUser", "registerAccount", "getParentsCount"]),
        ...mapMutations("ui", ["setConnectionModal"]),
    },
    watch: {
        getConnectionWallet(newVal, oldVal) {
            if (newVal && newVal !== oldVal) {
                const type = getConnectTypeFromSelectWallet(newVal)
                if (type === "default") {
                    this.register()
                }
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.alighting-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 70px 95px;
    background: rgba(22, 22, 32, 0.5);
    border-radius: 39px;
    text-align: center;
    max-width: 730px;
    margin: 0 auto 100px;
    border: 1px solid rgba(56, 201, 72, 0.6);

    @media screen and (max-width: 600px) {
        padding: 30px 15px;
        margin: 0 auto var(--main-offset);
    }

    &__inner {
        max-width: 540px;
        width: 100%;
        @media screen and (max-width: 600px) {
            max-width: 400px;
        }
    }
    &__title {
        margin-bottom: 10px;
        line-height: 35px;
        @media screen and (max-width: 600px) {
            line-height: 30px;
        }
    }
    &__subtitle {
        max-width: 400px;
        margin: 0 auto 25px;
        font-weight: 500;
        font-size: 26px;
        line-height: 37px;
        @media screen and (max-width: 600px) {
            font-size: 18px;
            line-height: 27px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 250px;
        }
    }
    &__image {
        width: 120px;
        height: 120px;
        border-radius: 100%;
        background-color: var(--color-silver);
        margin-bottom: 20px;
        overflow: hidden;
        @media screen and (max-width: 600px) {
            width: 100px;
            height: 100px;
            margin-bottom: var(--second-offset);
        }
    }

    &-info {
        display: flex;
        // grid-template-columns: 1fr;
        justify-content: center;
        gap: var(--main-offset);
        margin-bottom: var(--main-offset);
        @media screen and (max-width: 600px) {
            flex-direction: column;
            // grid-template-columns: 1fr;
            // gap: 10px;
            margin-bottom: var(--second-offset);
        }
        &__item {
            background-color: var(--color-cinder);
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px 15px 15px;
            @media screen and (max-width: 600px) {
                flex-direction: row;
                justify-content: space-between;
                padding: 15px;
            }
            p {
                margin-bottom: 6px;
                color: #828282;
                @media screen and (max-width: 600px) {
                    font-size: 14px;
                    line-height: 21px;
                    margin-bottom: 0;
                }
            }
            span {
                font-weight: 600;
                font-size: 30px;
                line-height: 45px;
                @media screen and (max-width: 600px) {
                    font-size: 22px;
                    line-height: 33px;
                }
            }
        }
    }

    &__now {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding-right: 20px;
        padding-left: 20px;
        margin-bottom: var(--second-offset);
    }
    &__wrong {
        &-button {
            font-weight: 600;
        }
        &-instruction {
            margin-top: var(--main-offset);
            display: flex;
            flex-direction: column;
            align-items: center;
            span {
                color: var(--color-emerald);
                display: inline-block;
                margin-bottom: 8px;
                @media screen and (max-width: 600px) {
                    margin-bottom: 5px;
                }
            }
        }
    }
}
</style>
